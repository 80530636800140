class SpectoGoogleMap {

    constructor(mapID, savePath) {
        this.mapid = mapID;
        this.savePath = savePath;
        this.googlemaps = window.location.protocol+'//'+window.location.hostname+'/google-maps';
        this.locationsJSON = {};
    }

    /**
     * Google Map
     * @param data (widget JSON)
     * @param json (saved JSON)
     */
    init(data, json) {
        let mapData = data;
        let locations = mapData.locations;
        let primaryLocation = locations[0];
        let savedData = json;

        // Cached Locations
        let cachedLocations = this.cachedLocations(savedData);

        // Primary Location Data
        let primaryCoordinates = {};
        let primaryAddress = primaryLocation.location;
        let foundLocation = cachedLocations.findIndex((x) => x === primaryAddress);

        let promiseVar = new Promise((resolve, reject) => {
            // Has Location Set & Location Not Saved Yet in JSON
            if(this.checkForLocation(primaryLocation) && foundLocation === -1) {
                this.geoLocate(primaryLocation.location, resolve);
            }
            // Location Saved (from JSON)
            else if(foundLocation !== -1)  {
                let locationObj = savedData[primaryAddress];
                primaryCoordinates = new google.maps.LatLng(parseFloat(locationObj.latitude), parseFloat(locationObj.longitude));
                resolve();
            }
            // Location Coordinates Set in CMS
            else {
                primaryCoordinates = new google.maps.LatLng(parseFloat(primaryLocation.latitude), parseFloat(primaryLocation.longitude));
                resolve();
            }
        });
        promiseVar.then((results) => {
            if(typeof results !== typeof undefined) {
                // Location
                let lat = results[0].geometry.location.lat();
                let lng = results[0].geometry.location.lng();
                primaryCoordinates = new google.maps.LatLng(lat, lng);
                let locationData = {};
                locationData[primaryAddress] = {'latitude': lat, 'longitude': lng};
                this.locationsJSON = $.extend(savedData, locationData);
                this.saveLocations();
            }

            // Init Maps
            let map = this.initMaps(mapData, primaryLocation, primaryCoordinates);

            // Additional Locations (Markers)
            this.markers(map, locations, cachedLocations, savedData);
        });
    }

    /**
     * Check IF Location Exists
     * @param map
     * @returns {boolean}
     */
    checkForLocation(map) {
        let geoLocate = true;
        if(map.latitude.length && map.longitude.length) {
            geoLocate = false;
        }
        return geoLocate;
    }

    /**
     * Get Location Names
     * @param savedData
     * @returns {Array}
     */
    cachedLocations(savedData) {
        let cachedLocations = [];
        for(let location in savedData) {
            cachedLocations.push(location);
        }
        return cachedLocations;
    }

    /**
     * Get Location Latitude & Longitude
     * @param address
     * @param resolve
     */
    geoLocate(address, resolve) {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            'address': address
        }, function (results, status) {
            if (status == 'OK') {
                resolve(results);
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    /**
     * Map Initialization
     * @param mapData (settinhs)
     * @param primaryLocation (object)
     * @param primaryCoordinates (coordinates)
     * @returns {google.maps.Map}
     */
    initMaps(mapData, primaryLocation, primaryCoordinates) {
        // Zoom & Map Type Configuration
        let zoom = parseInt(mapData.zoom.length ? mapData.zoom : 10);
        let mapType = mapData.map.length ? mapData.map : 'roadmap';

        let mapDiv = document.getElementById('gmap-'+this.mapid);
        let mapOptions = {
            center: primaryCoordinates,
            zoom: zoom,
            mapTypeId: mapType,
            zoomControl : true,
            zoomControlOpt: {
                style : 'SMALL',
                position: 'TOP_LEFT'
            },
            mapTypeControl: false
        };
        let map = new google.maps.Map(mapDiv, mapOptions);

        // Primary Marker
        this.createMarker(map, primaryCoordinates, primaryLocation);

        return map;
    }

    /**
     * Additional Markers
     * @param map (instance)
     * @param locations (object)
     * @param cachedLocations (array)
     * @param savedData (json)
     */
    markers(map, locations, cachedLocations, savedData) {
        let i = 0;
        for(let item of locations) {
            // Skip Primary Location
            if(i) {
                // Check IF Location Data Already Exists in JSON
                let foundLocation = cachedLocations.findIndex((x) => x === item.location);
                // Has Location Set & Location Not Saved Yet in JSON
                if (this.checkForLocation(item) && foundLocation === -1) {
                    let promiseVar = new Promise((resolve, reject) => {
                        this.geoLocate(item.location, resolve);
                    });

                    promiseVar.then((results) => {
                        let lat = results[0].geometry.location.lat();
                        let lng = results[0].geometry.location.lng();
                        let LatLng = new google.maps.LatLng(lat, lng);

                        // Create Marker
                        this.createMarker(map, LatLng, item);

                        // Save Location
                        let locationData = {};
                        locationData[item.location] = {'latitude': lat, 'longitude': lng};
                        this.locationsJSON = $.extend(this.locationsJSON, locationData);
                        this.saveLocations();
                    });
                }
                // Location Saved (from JSON)
                else if(foundLocation !== -1) {
                    let locationObj = savedData[item.location];
                    let LatLng = new google.maps.LatLng(parseFloat(locationObj.latitude), parseFloat(locationObj.longitude));
                    this.createMarker(map, LatLng, item);
                }
                // Location Coordinates Set in CMS
                else {
                    let LatLng = new google.maps.LatLng(parseFloat(item.latitude), parseFloat(item.longitude));
                    this.createMarker(map, LatLng, item);
                }
            }
            i++;
        }
    }

    /**
     * Save New Locations to JSON
     */
    saveLocations() {
        let savePath = this.savePath;
        let json = this.locationsJSON;
        $.ajax({
            method: 'POST',
            url: this.googlemaps,
            data: {savePath, json}
        })
        .done(function () {
            console.log("GoogleMaps Locations Saved");
        });
    }

    /**
     * Create Marker
     * @param map (instance)
     * @param LatLng (coordinates)
     * @param location (object)
     */
    createMarker(map, LatLng, location) {
        let marker = new google.maps.Marker({
            map: map,
            position: LatLng,
            title: location.markertitle
        });

        let infowindow = new google.maps.InfoWindow({
            content: `<p class="markerTitle">${location.markertitle}</p>
                        <p class="markerDesc">${location.markerdesc}</p>`
        });
        marker.addListener('click', function() {
            infowindow.open(map, marker);
        });
    }

}