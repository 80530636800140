// jQuery.browser.mobile
(function(a){(jQuery.browser=jQuery.browser||{}).mobile=/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))})(navigator.userAgent||navigator.vendor||window.opera);

jQuery('img.svg').each(function(){
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function(data) {
        // Get the SVG tag, ignore the rest
        var $svg = jQuery(data).find('svg');

        // Add replaced image's ID to the new SVG
        if(typeof imgID !== 'undefined') {
            $svg = $svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if(typeof imgClass !== 'undefined') {
            $svg = $svg.attr('class', imgClass+' replaced-svg');
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
        if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
            $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
        }

        // Replace image with new SVG
        $img.replaceWith($svg);

    }, 'xml');

});

jQuery(document).ready(function() {

	//Flip effect
	if (jQuery.browser.mobile === true ) {
		jQuery(".flipme").flip({trigger: "click"});
	}
	else {
		jQuery(".flipme").flip({trigger: "hover"});
	}

	//Match height
	jQuery(function() {
		jQuery('.equal-height').matchHeight({
			byRow: true,
			property: 'height',
			target: null,
			remove: false
		});
	});

	//open, close FAQ

	jQuery(function () {
		jQuery('.question').click(function () {
			jQuery('.question').removeClass('active');
			if(!jQuery(this).next('div').is(":visible")){
				jQuery(this).addClass('active');
			};
			jQuery(this).next('div').slideToggle(function(){
				jQuery('html,body').animate({scrollTop: jQuery('.active').offset().top-110},'slow');
			});

			jQuery(this).parent().siblings().children().next().slideUp();
			return false;
		});
	});

	//ob kliku na gumb, zamenjaj slide v slicku
	jQuery(".section-tabs .tab-head").each(function(){
		jQuery(this).click(function(){
			var itemIndex = jQuery(this).attr("num");
			jQuery(".section-tabs .tab-head.active").removeClass("active");
			jQuery(this).addClass("active");
			jQuery(".section-tabs  .tab-content.active").removeClass("active");
			jQuery(".section-tabs .tab-content[num='"+ itemIndex +"']").addClass("active");
		});
	});

	//Dropdown za language
	jQuery('#languages').click(function(){
		jQuery(this).toggleClass("expanded", 500);
	});

	//Mobile menu input reset
	jQuery(".mobile-menu input[type='checkbox']").each(function() {
		jQuery(this).attr('checked', false);
	});


	// FileManager
	$(document).on('click', '.js-filemanager .folder', function () {
		var current = $(this);
		var path = current.data('path');
		updateManager(path);
	});

	$(document).on('click', '.parentCategory', function () {
		var path = $('.js-filemanager').attr('data-parentPath');
		updateManager(path);
	});

	function updateManager(path) {
		let startPath = $('.js-loadManager').data('startfolder');
		let homeName = $('.js-loadManager').data('startfoldername');
		$.ajax({
			method:'POST',
			url: 'http://'+window.location.host+'/filemanager/folders/show',
			data: {path, startPath, homeName},
			dataType : "html"
		})
		.done(function (data) {
			$('.js-loadManager').html(data);
		});
	}

	// Google Maps
	initGoogleMaps();
	function initGoogleMaps() {
		$('div[id^="gmap-"]').each(function () {
			let current = $(this);
			let mapID = current.prop('id').split('-');
			mapID = mapID.pop();
			let mapData = current.data('gmaps');
			let mapJSON = current.data('savedlocations');
			let savePath = current.data('savepath');
			this['mapObj'+ mapID] = new SpectoGoogleMap(mapID, savePath);
			this['mapObj'+ mapID]['init'](mapData, mapJSON);
		});
	}

	$("#fileURL").on("click", function () {
		$(this).select();
	});

	if (!jQuery.browser.mobile) {
		gmapHeight();
	}
});

function showURL(url){
	$('#fileURL').val(url);
	$('#myModal').modal('toggle');
	//alert(url);
	return false;
}

function gmapHeight() {
	let mapSection = $('.js-mapSection');
	if(mapSection.length) {
		let height = mapSection.find('.js-content').height();
		$('#gmap-1').css('height', height + 250);
	}
}

$(window).resize(function() {
	var winWidth = $(window).width();
	if(winWidth > 768 && !jQuery.browser.mobile) {
		gmapHeight();
	}
	else {
		$('#gmap-1').css('height', 'auto');
	}

});